<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12">
                    <v-toolbar color="primary" dark>
                        <v-spacer />
                        <v-toolbar-title>Set Password</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-form v-model="isFormValid" @submit.prevent="changePassword">
                        <v-card-text class="pb-0 mt-2">
                            <v-text-field
                                v-model="password"
                                :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                :rules="[rules.required, rules.min, rules.max]"
                                :type="show ? 'text' : 'password'"
                                label="Enter New Password"
                                hint="At least 8 characters"
                                counter
                                outlined
                                autofocus 
                                @click:append="show = !show" />
                            <v-text-field
                                v-model="rePassword"
                                :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                :rules="[rules.required, passwordsMatch]"
                                :type="show1 ? 'text' : 'password'"
                                label="Re-enter New Password"
                                hint="At least 8 characters"
                                counter
                                outlined
                                @click:append="show1 = !show1" />
                        </v-card-text>
                        <v-divider />
                        <v-card-actions class="mx-3 py-4">
                            <v-btn
                                v-if="error"
                                class="px-5 text-none"
                                color="primary"
                                rounded
                                outlined
                                @click="forgotPassword">
                                Forgot Password
                            </v-btn>
                            <v-btn
                                v-if="error"
                                class="px-5 text-none"
                                color="primary"
                                rounded
                                @click="login">
                                Go to Login
                            </v-btn>
                            <v-spacer />
                            <v-btn
                                type="submit"
                                class="px-5 text-none"
                                color="primary"
                                rounded
                                :disabled="!isFormValid">
                                Reset Password
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { resetPassword } from '@/features/account/services/accountService';

export default {
    props: {
        token: {
            type: String,
            default: null,
        }
    },

    data() {
        return {
            show: false,
            show1: false,
            password: "",
            rePassword: "",
            error: false,
            isFormValid: false,
            rules: {
                required: value => !!value || "Required.",
                min: value => value.length >= 8 || "Min 8 characters",
                max: value => value.length <= 50 || "Max 50 characters",
            },
        };
    },

    computed: {
        passwordsMatch() {
            return this.password === this.rePassword || "Password must match";
        },
    },

    methods: {
        ...mapActions({
            refreshUser: "authentication/refreshUser"
        }),
        async changePassword() {
            try {
                await resetPassword(this.token, this.password);
            }
            catch(e) {
                this.error = true;
                throw e;
            }
            await this.refreshUser();
            this.$router.push("/");
        },
        login() {
            this.$router.push("/login");
        },
        forgotPassword() {
            this.$router.push("/request-reset-password");
        }
    }
};
</script>
